import React, { FC } from "react"
import { PageProps } from "gatsby"

import Heading from "../components/heading"
import SEO from "../components/seo"
import Text from "../components/text"
import Wrapper from "../containers/wrapper"

interface Props extends PageProps {}

const AboutPage: FC<Props> = () => (
    <Wrapper>
        <SEO title={`About`} />
        <Heading as="h1" fontSize={8} mb={4}>About</Heading>
        <Text fontSize={2}>This site is not officially affiliated with Pokémon Go and is intended to fall under Fair Use doctrine, similar to any other informational site such as a wiki.</Text>
        <Text fontSize={2}>All Pokémon Go trademarks, copyrights etc are held by Niantic, Inc.; Pokémon; and Nintendo / Creatures Inc. / GAME FREAK inc. Pokémon and Pokémon character names are trademarks of Nintendo.</Text>
    </Wrapper>
)

export default AboutPage
